import React, {useState, useEffect} from 'react'
import {Calendar, Modal, Badge, Table, Spin, Select, Row, Col} from 'antd'
import dayjs, {Dayjs} from 'dayjs'
import styled from 'styled-components'
import withPageContext from '../../hocs/withPageContext'
import {getDeliveryDetails} from '../../api/deliveryDetail'
import locale from 'antd/es/calendar/locale/ko_KR'

interface RecordType {
  id: number
  serialNumber: string
  status: string
  userName: string
  modelName: string
  type: string
  deliveryTime: string
  accountCode: string
  orderType: string
  date: string
}

interface ShowProps {
  records: RecordType[]
}

const koreanMonths = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']

const Show: React.FC<ShowProps> = ({records}) => {
  const columns = [
    {
      title: '제품군 (OrderType)',
      dataIndex: 'orderType',
      key: 'orderType'
    },
    {
      title: '고객명 (END USER)',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: '모델명 (HI MODEL)',
      dataIndex: 'modelName',
      key: 'modelName'
    },
    {
      title: 'S/N',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: '출하유형',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '배송시간',
      dataIndex: 'deliveryTime',
      key: 'deliveryTime'
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status'
    }
  ]
  return <Table columns={columns} dataSource={records} rowKey="id" pagination={{pageSize: 10}} />
}

const SAPLogCalendar: React.FC = () => {
  const [monthData, setMonthData] = useState<RecordType[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(dayjs())

  useEffect(() => {
    fetchMonthData(dayjs(selectedMonth).startOf('month'), dayjs(selectedMonth).endOf('month'))
  }, [selectedMonth])

  const fetchMonthData = async (startDate: Dayjs, endDate: Dayjs) => {
    setLoading(true)
    setMonthData([])
    try {
      const response = await getDeliveryDetails({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      })
      setMonthData(response.data)
    } catch (error) {
      console.error('Error fetching delivery details:', error)
    } finally {
      setLoading(false)
    }
  }

  const dateCellRender = (value: Dayjs) => {
    const listData = monthData.filter(
      (item) =>
        dayjs(item.date).isSame(value, 'day') &&
        dayjs(value).format('dddd') !== 'Sunday' &&
        dayjs(value).format('dddd') !== 'Saturday'
    )
    return (
      <ul className="events" style={{listStyle: 'none', padding: 0}}>
        {listData.map((item) => (
          <li key={item.id}>
            <Badge status={item.status === '납품대기' ? 'processing' : 'success'} text={item.modelName} />
          </li>
        ))}
      </ul>
    )
  }

  const monthCellRender = (date: Dayjs) => {
    const month = date.month()
    return <div>{koreanMonths[month]}</div>
  }

  const handleDateSelect = (date: Dayjs) => {
    const selectedEvents = monthData.filter((item) => dayjs(item.date).isSame(date, 'day'))
    if (selectedEvents.length > 0) {
      Modal.info({
        title: 'SAP 로그 (' + date.format('YYYY-MM-DD') + ')',
        content: <Show records={selectedEvents} />,
        width: 1000,
        okText: 'Close',
        maskClosable: true
      })
    }
  }

  const onPanelChange = (date: Dayjs) => {
    setSelectedMonth(date)
  }

  const headerRender = ({value, type, onChange, onTypeChange}: any) => {
    const start = 0
    const end = 12
    const monthOptions: any = []

    const current = value.clone()
    const localeData = value.localeData()
    const months: any = []
    for (let i = 0; i < 12; i++) {
      current.month(i)
      months.push(localeData.monthsShort(current))
    }

    for (let index = start; index < end; index++) {
      monthOptions.push(
        <Select.Option className="month-item" key={`${index}`}>
          {koreanMonths[index]}
        </Select.Option>
      )
    }

    const year = value.year()
    const month = value.month()

    // Calculate a range of years (e.g., 10 years before and after the current year)
    const startYear = year - 10
    const endYear = year + 10
    const yearOptions: any = []
    for (let i = startYear; i <= endYear; i++) {
      yearOptions.push(
        <Select.Option key={i} value={i}>
          {i}년
        </Select.Option>
      )
    }

    return (
      <div style={{padding: 8}}>
        <Row gutter={8}>
          <Col style={{marginLeft: 'auto'}}>
            <Select
              className="my-year-select"
              onChange={(newYear) => {
                const now = value.clone().year(newYear)
                onChange(now)
              }}
              value={String(year)}
            >
              {yearOptions}
            </Select>
          </Col>
          <Col>
            <Select
              value={String(month)}
              onChange={(selectedMonth) => {
                const newValue = value.clone()
                newValue.month(parseInt(selectedMonth, 10))
                onChange(newValue)
              }}
            >
              {monthOptions}
            </Select>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div>
      <Spin spinning={loading}>
        <StyledCalendar<any>
          mode="month"
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
          headerRender={headerRender}
          onSelect={handleDateSelect}
          onPanelChange={onPanelChange}
          locale={locale}
        />
      </Spin>
    </div>
  )
}

export default withPageContext(SAPLogCalendar, getDeliveryDetails)

const StyledCalendar = styled(Calendar)`
  .ant-picker-calendar-mode-switch {
    display: none;
  }
`
